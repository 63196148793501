import { mock } from 'src/utils/axios';
import wait from 'src/utils/wait';
import { sign, decode, JWT_SECRET, JWT_EXPIRES_IN } from 'src/utils/jwt';
import randomId from 'src/utils/randomId';
import { Lead } from '../models/leads';

export const leads: Lead[] = [
  {
    id: '1',
    code: 'LEAD-1',
    firstName: 'John',
    lastName: 'Doe',
    fullName: 'John Doe',
    document: '123456789',
    email: 'john.doe@email.com',
    phone: '123456789',
    budget: 15600,
    registerDate: '2021-01-01',
    registeredBy: 'Nancy Boada'
  }
];
