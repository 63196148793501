import type { ReactNode } from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InventoryIcon from '@mui/icons-material/Inventory';
import StoreIcon from '@mui/icons-material/Store';
import CategoryIcon from '@mui/icons-material/Category';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'Home',
    items: [
      {
        name: 'Dashboard',
        icon: DashboardIcon,
        link: '/home/admin-dashboard',
      },
      {
        name: 'Órdenes',
        icon: ReceiptIcon,
        link: '/home/sales',
      },
    ],
  },
  {
    heading: 'Administración',
    items: [
      {
        name: 'Productos',
        link: '/management/products',
        icon: InventoryIcon,
        items: [
          {
            name: 'Listar',
            link: '/management/products/list',
          },
          {
            name: 'Crear',
            link: '/management/products/create',
          },
          {
            name: 'Categorías',
            link: '/management/products/categories',
          },
          {
            name: 'Subcategorías',
            link: '/management/products/subcategories',
          },
        ],
      },
      {
        name: 'Tienda',
        link: '/management/store/list',
        icon: StoreIcon,
      },
      {
        name: 'Tienda2',
        link: '/management/store2',
        icon: StoreIcon,
      },
      {
        name: 'Catálogos',
        link: '/management/catalogues/list',
        icon: CategoryIcon,
      },
      {
        name: 'Catálogos2',
        link: '/management/catalogues2',
        icon: CategoryIcon,
      },
    ],
  },
];

export default menuItems;
