import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Components
const Products = Loader(lazy(() => import('src/content/Management/Products')));
const CreateProduct = Loader(
  lazy(() => import('src/content/Management/Products/create')),
);
const Categories = Loader(
  lazy(() => import('src/content/Management/Categories')),
);
const Subcategories = Loader(
  lazy(() => import('src/content/Management/Subcategories')),
);
const EditProduct = Loader(
  lazy(() => import('src/content/Management/Products/edit')),
);
const Store = Loader(lazy(() => import('src/content/Management/Store')));
const Store2 = Loader(lazy(() => import('src/content/Management/Store2')));
const Catalogues = Loader(
  lazy(() => import('src/content/Management/Catalogues')),
);
const Catalogues2 = Loader(
  lazy(() => import('src/content/Management/Catalogues2')),
);

const homeRoutes = [
  {
    path: '',
    element: <Navigate to="users" replace />,
  },
  {
    path: 'products',
    children: [
      {
        path: 'list',
        element: <Products />,
      },
      {
        path: 'create',
        element: <CreateProduct />,
      },
      {
        path: 'edit/:id',
        element: <EditProduct />,
      },
      {
        path: 'categories',
        element: <Categories />,
      },
      {
        path: 'subcategories',
        element: <Subcategories />,
      },
    ],
  },
  {
    path: 'store/*',
    element: <Store />,
  },
  {
    path: 'store2',
    element: <Store2 />,
  },
  {
    path: 'catalogues2',
    element: <Catalogues2 />,
  },
  {
    path: 'catalogues/*',
    element: <Catalogues />,
  },
];

export default homeRoutes;
